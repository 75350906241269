import { mapInit, mapDefaultPan, mapBootstrappedEvent, setLegends } from "./googleMap";
import { initDataLayers, assignLayersEventHandlers } from "../components/dataLayers";

import { loadState, loadQuote } from "./loadQuote";
import { clientBlockingErrorEvent } from "../components/blockingErrorModal";
import { clientAppBootstrappedEvent } from "../components/appIsLoading";
import { isTerritoryQuotable } from "../core/selectors/territorySelector";
import { constructParams, isMissingTerritoryId, isUrlContainMode, getUrlTerritoryId } from "./urlHeplers";
import store from "../store";
import { updateUrlParams } from "../core/actions/urlActions";
import { territoryActivate } from "../core/actions/territoryActions";
import { waitForLogin } from "./firebase/auth";
import { retryOperation } from "./asyncHelper";
import { URL_MODE_PARAMS } from "../const/urlParams";

// This is the callback to google map API
global.mapApiLoaded = async () => {
    let quoteData;
    // quote ID is the only thing in the query
    const urlParams = constructParams(window.location.search);

    store.dispatch(updateUrlParams(urlParams));

    try {
        await retryOperation(waitForLogin, 5, 1000);

        if (isMissingTerritoryId(urlParams)) {
            const message = `TERRITORY_ID parameter expected to render a single territory`;
            document.dispatchEvent(clientBlockingErrorEvent(message));
        } else {
            const quoteId = urlParams.quoteId;
            quoteData = await loadQuote(quoteId);
        }
    } catch (error) {
        // Something went wrong with the quote or its ID
        console.log(error);
        // construct friendly message
        const message = `We could not find the quote you are after, please check the link you've used to open this page and try again`;
        document.dispatchEvent(clientBlockingErrorEvent(message));
    }

    // prepare map for the quote data rendering
    bootstrapMap();

    if (quoteData) {
        try {
            // filter quotable territories on client view app
            quoteData.territories = quoteData.territories.filter((territory) => isTerritoryQuotable(territory));
            await loadState(quoteData, {
                loadCustomer: false,
                useQuoteSettings: true,
            });

            // if the mode is single territory, we need to activate the territory overriding saved state
            if (isUrlContainMode(URL_MODE_PARAMS.SINGLE_TERRITORY)) {
                const territoryId = getUrlTerritoryId();
                if (territoryId) {
                    store.dispatch(territoryActivate(territoryId));
                }
            }

            setLegends();
        } catch (error) {
            // something went wrong when loading state
            console.log(error);
            const message = `We experinece some difficulties, please try again or use a different browser.`;
            document.dispatchEvent(clientBlockingErrorEvent(message));
        }
    }

    document.dispatchEvent(clientAppBootstrappedEvent);
};

const bootstrapMap = () => {
    // init the map it its wrapper
    mapInit(document.getElementById("map"));
    mapDefaultPan();
    initDataLayers();
    assignLayersEventHandlers({ rightClick: { RURAL: true } });
    // allow other components to bind
    document.dispatchEvent(mapBootstrappedEvent);
};
