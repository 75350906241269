import clonedeep from "lodash-es/cloneDeep";

import { PROXIMITY_PLAN_UPDATE, PROXIMITY_PLAN_RESET } from "../actions/actionTypes";

const initialState = {
    
};

export const proximityPlan = (state = null, action) => {
    switch (action.type) {
        default:
            return state;
    }
};
