import { addDoc, collection, doc, setDoc, updateDoc } from "firebase/firestore";
import clonedeep from "lodash-es/cloneDeep";

import { getQuoteTag, getTargetingTags, pushTag } from "../analytics/tags";
import { auth, firestore } from "../firebase/init";
import { GMAP_ROUNDS_LAYERS } from "../../const/dataLayers";
const { COUNTERS } = GMAP_ROUNDS_LAYERS;
import { quoteMetaUpdate, updateUiSettings } from "../../core/actions/actions";
import store from "../../store";
import { plainWarning } from "../notifications/apiWrapper";

const quoteCollRef = collection(firestore, "quotes");

export const save = async (asNew = false) => {
    let result = true;

    const newDoc = clonedeep(store.getState());

    // Add potential missing distribution data
    if (!newDoc.quoteDetails.distribution[COUNTERS]) {
        newDoc.quoteDetails.distribution[COUNTERS] = { freight: 0, volume: 0}
    }

    // split out meta data
    let meta = clonedeep(newDoc.quoteMeta);

    // following states are not required for saving
    delete newDoc.appRouter;
    delete newDoc.mapBuildingMode;
    delete newDoc.mapDrawingMode;
    delete newDoc.mapRangeMode;
    delete newDoc.mapRangeValues;
    delete newDoc.territorialFilters;
    delete newDoc.emptyWalks;
    delete newDoc.urlParams;
    delete newDoc.roundsData;
    // remove contact
    delete newDoc.quoteMeta.contact;

    // remove bounding box
    newDoc.territories.forEach(
        (territory) => delete territory.location.boundingBox
    );

    try {
        // enforce new quote
        if (asNew) {
            meta.id = "";
            newDoc.quoteDetails.locked = false;
            newDoc.quoteMeta.jobNumber = "";
            newDoc.quoteMeta.jobName = "";
            newDoc.quoteMeta.campaignStartDate = null;

            delete newDoc.quoteMeta.jobInformation;
            delete newDoc.quoteMeta.jobContractNumber;
            delete newDoc.quoteMeta.campaignId;
            delete newDoc.quoteMeta.campaignName;
        }

        if (auth.currentUser?.email) {
            // always override updatedBy
            meta.updatedBy = auth.currentUser.email;
            meta.updatedOn = new Date().toISOString();

            if (!meta.createdBy) {
                meta.createdBy = auth.currentUser.email;
            }

            // ensure current user is always in tags
            if (!meta.tags.includes(auth.currentUser.email.toLowerCase())) {
                meta.tags.push(auth.currentUser.email.toLowerCase());
            }
        }

        let quoteDocRef;
        let pushTagEvent = "quote-edit";

        if (meta.id) {
            // ensure quite id is always in tags
            if (!meta.tags.includes(meta.id.toLowerCase())) {
                meta.tags.push(meta.id.toLowerCase());
            }

            // updating quote
            quoteDocRef = doc(firestore, quoteCollRef.path, meta.id);
            await setDoc(quoteDocRef, newDoc);
        } else {
            pushTagEvent = "quote-new";
            // set creator
            meta.createdBy = auth.currentUser.email;
            meta.createdOn = new Date().toISOString();

            // create doc
            quoteDocRef = await addDoc(quoteCollRef, newDoc);
            meta.id = quoteDocRef.id;

            // update tags with quote ID
            meta.tags.push(meta.id.toLowerCase());
        }

        pushTag(pushTagEvent, getQuoteTag(meta.id));

        // report on targeting usage
        getTargetingTags(meta.id, newDoc.targetingFilters).forEach((tag) =>
            pushTag("targeting-used", tag)
        );

        // async update meta, don't wait here
        await updateDoc(quoteDocRef, {
            "quoteMeta.id": meta.id,
            "quoteMeta.tags": meta.tags,
            "quoteMeta.createdOn": meta.createdOn,
            "quoteMeta.createdBy": meta.createdBy,
            "quoteMeta.updatedOn": meta.updatedOn,
            "quoteMeta.updatedBy": meta.updatedBy,
        });

        // ensure the meta state is updated
        store.dispatch(quoteMetaUpdate(meta));
        // Dismiss save reminder
        store.dispatch(updateUiSettings({ showSaveReminder: false }));
    } catch (error) {
        console.error("Failed to save the quote!");
        console.log(error);
        result = false;
    }

    return result;
};

export const updateQuotePdf = async (quotePdf) => {
    return await updateProp(
        { quotePdf },
        "Can not save quote PDF. Network issues!"
    );
};

export const saveQuoteSettings = async () => {
    const settings = store.getState().settings;
    return await updateProp(
        { settings },
        "Can not save quote settings. Network issues!"
    );
};

const updateProp = async (prop, errorMsg) => {
    const quoteDocRef = doc(quoteCollRef, store.getState().quoteMeta.id);
    try {
        await updateDoc(quoteDocRef, prop);
        return true;
    } catch (error) {
        plainWarning(errorMsg, error);
        return false;
    }
};
