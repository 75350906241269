import clonedeep from "lodash-es/cloneDeep";

import { CATCHMENT_PLAN_UPDATE, CATCHMENT_PLAN_RESET } from "../actions/actionTypes";

// The state itself is a dummuy placeholder where most of its values are set dynamically for API calls purposes
const initialState = {
    schema: "master-catchment",
    schema_version: "1.0",
    store_id: null,
    store_name: null,

    cms_acct_code: null,    // IMPORTANT: this value must be set dynamically whenever API needs it, 
                            // but might not be present in the state object itself

    configuration: {    // IMPORTANT: this object properties must be set dynamically whenever API needs it, 
                        // but might not be present in the state object itself

        sputnik_quote_id: null, 
        lodgement_site: null,
        store_copies: 0,
        store_copies_fee: 0
    },
    plans: [] // IMPORTANT: this array must be filled dynamically whenever API needs it
};

export const storeCatchmentPlan = {
    plan_id: null,
    plan_name: null,
    channel_selections: {},
    rounds: []
};

export const catchmentPlan = (state = null, action) => {
    
    const next = clonedeep(state);

    switch (action.type) {
        case CATCHMENT_PLAN_UPDATE:
            // consider the case where the payload is null to rid of the plan
            return action.payload === null ?  null : { ...next, ...action.payload };
        case CATCHMENT_PLAN_RESET:
            return clonedeep(initialState);
        default:
            return state;
    }
};