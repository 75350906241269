import { clientInfoNoDelayEvent } from "../components/toast";

export const copyToClipboard = (text, infoMessage) => {
    navigator.clipboard.writeText(text).then(
        () => {
            global.document.dispatchEvent(
                clientInfoNoDelayEvent({ message: infoMessage })
            );
        },
        () => {}
    );
}