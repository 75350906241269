import { LitElement, html } from "lit-element";

import "./progressSpinner";

export const clientAppBootstrappedEvent = new Event("client:app:bootstrapped");

export class AppIsLoading extends LitElement {
    static get properties() {
        return {
            hidden: { type: Boolean },
        };
    }

    firstUpdated() {
        global.document.addEventListener(
            clientAppBootstrappedEvent.type,
            () => {
                // remove itself from DOM
                this.parentElement.removeChild(this);
            }
        );
    }

    /**
     * Render template into light DOM.
     */
    createRenderRoot() {
        return this;
    }

    render() {
        return html`<rm-progress-spinner
            class="${this.hidden ? "hidden" : ""}"
        ></rm-progress-spinner>`;
    }
}

global.customElements.define("rm-app-is-loading", AppIsLoading);
