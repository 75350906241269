/**
 * Add fixed number support
 * @param {Number} digits Number of digits to keep/produce
 * @param {Number} base Optional base, decimal by default
 */
Number.prototype.toFixedNumber = function (digits, base = 10) {
    const pow = Math.pow(base, digits);
    return Math.round(this * pow) / pow;
};

/**
 * Formats numeric output according to locale
 *
 * @param {Number} value
 */
export const numberFormat = (value, options) => {
    return new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        ...options,
    }).format(value);
};

/**
 * Formats currency output according to locale
 *
 * @param {Number} value
 */
export const currencyFormat = (value, options) => {
    return new Intl.NumberFormat(undefined, {
        style: "currency",
        currency: "NZD",
        ...options,
    }).format(value);
};

export const financialNumberFormat = (value) => {
    return Number.parseFloat(value).toFixed(2);
};

export const formatPhoneNumber = (value) => {
    if (!value) return "";
    return value.replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "");
};
