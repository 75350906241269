import { combineReducers } from "redux";

import { networkVisibility } from "./mapLayersReducer";
import { mapBuildingMode } from "./mapBuildingModeReducer";
import { mapDrawingMode } from "./mapDrawingModeReducer";
import { mapRangeMode } from "./mapRangeModeReducer";
import { mapRangeValues } from "./mapRangeValuesReducer";
import { roundsData } from "./roundsDataReducer";
import { emptyWalks } from "./emptyWalksReducer";
import { activeTerritory } from "./activeTerritoryReducer";
import { printingSpecs } from "./printingSpecs";
import { quoteDetails } from "./quoteDetails";
import { quoteMeta } from "./quoteMetaReducer";
import { territorialFilters } from "./territorialFiltersReducer";
import { targetingFilters } from "./targetingFiltersReducer";
import { territories } from "./territoryReducer";
import { appRouter } from "./appRouterReducer";
import { urlParams } from "./urlParamsReducer";
import { settings } from "./quoteSetting";
import { uiSettings } from "./uiSettings";
import { proximityPlan } from "./proximityPlanReducer";
import { catchmentPlan } from "./catchmentPlanReducer";

const quoting = combineReducers({
    appRouter,
    networkVisibility,
    mapBuildingMode,
    mapDrawingMode,
    mapRangeMode,
    mapRangeValues,
    roundsData,
    activeTerritory,
    printingSpecs,
    quoteDetails,
    quoteMeta,
    territories: (state = []) => state, // has to be init with a dummy reducer
    targetingFilters: (state = []) => state,
    territorialFilters,
    emptyWalks,
    urlParams,
    settings,
    uiSettings,
    proximityPlan,
    catchmentPlan,
});

// Cross slice reducer to provide into territories reducer
const crossSlice_1 = (state, action) => {
    if (/TERRITORY_[A-Z]+|TERRITORIES_RESET/.test(action.type)) {
        return {
            ...state,
            territories: territories(state.territories, action, {
                activeTerritory: state.activeTerritory,
                networkVisibility: state.networkVisibility,
                roundsData: state.roundsData,
            }),
        };
    }
    return state;
};

// Cross slice reducer to provide into targeting reducer
const crossSlice_2 = (state, action) => {
    if (
        /TARGETING_[A-Z]+|TERRITORY_UPDATE_ROUNDS|TERRITORY_DELETE|TERRITORIES_RESET/.test(
            action.type
        )
    ) {
        return {
            ...state,
            targetingFilters: targetingFilters(state.targetingFilters, action, {
                activeTerritory: state.activeTerritory,
            }),
        };
    }
    return state;
};

// manual reduce-reducers approach per Redux docs
const rootReducer = (state, action) => {
    const is_0 = quoting(state, action);
    const is_1 = crossSlice_1(is_0, action);
    const is_2 = crossSlice_2(is_1, action);

    return is_2;
};

export default rootReducer;
