import {
    TERRITORY_ADD,
    TERRITORY_UPDATE_LOCATION,
    TERRITORY_UPDATE_ROUNDS,
    TERRITORY_COPY,
    TERRITORY_PUSH,
    TERRITORY_UPDATE_VOLUME_SELECTIONS,
    TERRITORY_UPDATE_NETWORK_SELECTIONS,
    TERRITORY_ORDERING,
    TERRITORY_ACTIVATE,
    TERRITORY_DELETE,
    TERRITORY_FLUSH_ROUNDS,
    TERRITORY_ROUND_VISIBILITY_TOGGLE,
    TERRITORIES_RESET,
    TERRITORY_SET_COLOUR,
    TERRITORY_NEIGHBOUR_ROUNDS,
    TERRITORY_LOAD,
    TERRITORY_ROUNDS_APPLY_TARGETING,
    TERRITORY_QUOTABLE_TOGGLE,
    TERRITORY_TOGGLE_EMPTY_WALKS_SELECTION,
    TERRITORY_UPDATE_CUSTOMER_CHANGES,
    TERRITORY_UPDATE,
    TERRITORY_UPDATE_ROUNDS_SELECTION,
    TERRITORY_UPDATE_AND_RECALCULATE,
} from "./actionTypes";

import guid from "../../services/guid";

export const territoryUpdateRoundsSelection = (payload, territoryId = null) => {
    return {
        type: TERRITORY_UPDATE_ROUNDS_SELECTION,
        payload: { territoryId: territoryId, rounds: payload },
    };
};

export const territoryUpdateRounds = (payload, roundsSelected = true) => {
    return {
        type: TERRITORY_UPDATE_ROUNDS,
        payload,
        roundsSelected,
    };
};

export const territoryNeighbourRounds = (payload) => {
    return {
        type: TERRITORY_NEIGHBOUR_ROUNDS,
        payload,
    };
};

/**
 * Generate new territory ID to provide blank territory with
 */
export const territoriesReset = () => {
    return {
        type: TERRITORIES_RESET,
        payload: `rm_${guid()}`,
    };
};

export const territorySetColour = (payload) => {
    return {
        type: TERRITORY_SET_COLOUR,
        payload,
    };
};

export const territoryUpdateVolumeSelections = (payload) => {
    return {
        type: TERRITORY_UPDATE_VOLUME_SELECTIONS,
        payload,
    };
};

export const territoryUpdateNetworkSelections = (payload) => {
    const selections = Array.isArray(payload) ? payload : Object.keys(payload).filter((item) => payload[item]);
    return {
        type: TERRITORY_UPDATE_NETWORK_SELECTIONS,
        payload: selections,
    };
};

export const territoryUpdateLocation = (location, territoryId = null) => {
    return {
        type: TERRITORY_UPDATE_LOCATION,
        payload: { territoryId: territoryId, location: location },
    };
};

export const territoryLoad = (payload) => {
    return {
        type: TERRITORY_LOAD,
        payload,
    };
};

export const territoryAdd = (payload) => {
    return {
        type: TERRITORY_ADD,
        payload,
    };
};

export const territoryUpdate = (payload) => {
    return {
        type: TERRITORY_UPDATE,
        payload,
    };
};

export const territoryDelete = (payload) => {
    return {
        type: TERRITORY_DELETE,
        payload,
    };
};

export const territoryActivate = (payload) => {
    return {
        type: TERRITORY_ACTIVATE,
        payload,
    };
};

export const territoryRoundVisibilityToggle = (payload) => {
    return {
        type: TERRITORY_ROUND_VISIBILITY_TOGGLE,
        payload,
    };
};

export const territoryFlushRounds = () => {
    return {
        type: TERRITORY_FLUSH_ROUNDS,
    };
};

export const territoryApplyTargeting = (payload) => {
    return {
        type: TERRITORY_ROUNDS_APPLY_TARGETING,
        payload,
    };
};

export const territoryCopy = (territory, newId) => {
    return {
        type: TERRITORY_COPY,
        payload: { territory: territory, newId: newId },
    };
};

export const territoryOrdering = (oldIndex, newIndex) => {
    return {
        type: TERRITORY_ORDERING,
        payload: { oldIndex: oldIndex, newIndex: newIndex },
    };
};

export const territoryQuotableToggle = (territoryId, quotable) => {
    return {
        type: TERRITORY_QUOTABLE_TOGGLE,
        payload: { territoryId: territoryId, quotable: quotable },
    };
};

export const toggleSelectAllEmpty = (selectAllEmpty, emptyWalks) => {
    return {
        type: TERRITORY_TOGGLE_EMPTY_WALKS_SELECTION,
        payload: { selectAllEmpty, emptyWalks },
    };
};

export const territoryUpdateAndRecalculate = (territoryId = null) => {
    return {
        type: TERRITORY_UPDATE_AND_RECALCULATE,
        payload: { territoryId },
    };
};


// this action will activate the territory too, see the other reducer
export const territoryPush = (payload) => {
    return {
        type: TERRITORY_PUSH,
        payload,
    };
};

export const territoryUpdateCustomerChanges = (payload) => {
    return {
        type: TERRITORY_UPDATE_CUSTOMER_CHANGES,
        payload,
    };
};
