import { LitElement, html } from "lit-element";
import { subscribe } from "redux-subscribe";

import { noop, updateUiSettings } from "../../core/actions/actions";
import store from "../../store";

import { isQuoteId, loadQuote, loadState } from "../../services/loadQuote";
import "../progressSpinner";
import { clientWarningEvent } from "../toast";
import { territoryActivate } from "../../core/actions/territoryActions";

export const LOAD_QUOTE_PATH = "load-quote";
export const LOAD_QUOTE_ACTIVATE_TERRITORY = "activate-territory";

class RSLoadQuote extends LitElement {
    static get properties() {
        return {
            stateRoute: { type: String },
            quoteId: { type: String },
            territoryId: { type: String },
            nextRoute: { type: String },
            showSaveReminder: { type: Boolean },
        };
    }

    constructor() {
        super();
        store.dispatch(
            subscribe(
                "appRouter",
                `RouteService_CoolWatcher`,
                (data) => {

                    if ( [data.next.current, data.prev.current].includes(LOAD_QUOTE_PATH)) {
                        this.stateRoute = data.next.current;
                        const paramsUrl = new URL(
                            `${window.location.protocol}//${window.location.host}/${data.next.params[0]}`
                        );
                                     
                        this.quoteId = LOAD_QUOTE_PATH === this.stateRoute ? data.next.params[0] : "";
                        
                        // exatract reminder param
                        const params = paramsUrl.searchParams;
                        this.showSaveReminder = params.get("reminder") === "true";
                        
                        this.nextRoute = data.next.params.length > 0 ? data.next.params.slice(1).join("/") : "";
                    }

                    if (data.next.current.includes(LOAD_QUOTE_ACTIVATE_TERRITORY)) {
                        this.stateRoute = data.next.current;
                        this.territoryId = data.next.params[0];

                        this.nextRoute = data.next.params.length > 0 ? data.next.params.slice(1).join("/") : "";
                    }

                    return noop(`RouteService_CoolWatcher`);
                }
            )
        );
    }

    async updated(changedProps) {
        if (![LOAD_QUOTE_PATH, LOAD_QUOTE_ACTIVATE_TERRITORY].includes(this.stateRoute)) {
            return;
        }

        try {
            if (changedProps.has("territoryId") && this.territoryId) {
                // activate territory
                await store.dispatch(territoryActivate(this.territoryId));

                // redirect to the next route
                this.nextRoute ? 
                    global.location.assign(`/#/${this.nextRoute}`) 
                    : global.history.replaceState(null, '', `${window.location.protocol}//${window.location.host}`);


                return;
            }
        } catch (error) {
            console.error('[Territory Activation] Error:', error);
        }
        
        if (changedProps.has("quoteId") && this.quoteId) {
            // validate quote ID
            if (isQuoteId(this.quoteId)) {
                let quoteData;
                try {
                    quoteData = await loadQuote(this.quoteId);
                } catch (error) {
                    global.document.dispatchEvent(
                        clientWarningEvent({
                            message:
                                "Could not load quote by ID provided in the link.",
                        })
                    );
                }
                if (quoteData) {
                    try {
                        await loadState(quoteData);

                        store.dispatch(
                            updateUiSettings({
                                showSaveReminder: this.showSaveReminder,
                            })
                        );
                    } catch (error) {
                        global.document.dispatchEvent(
                            clientWarningEvent({
                                message: "Could not load quote data.",
                            })
                        );
                    }
                }

                // redirect to the next route
                this.nextRoute ? 
                    global.location.assign(`/#/${this.nextRoute}`) 
                    : global.history.replaceState(null, '', `${window.location.protocol}//${window.location.host}`);

                return;
            } else {
                // prompt user quote ID is not valid
                global.document.dispatchEvent(
                    clientWarningEvent({ message: "Quote ID is malformed." })
                );
                global.location.assign(`${window.location.protocol}//${window.location.host}`);
                return;
            }
        } else {
            // prompt user quote ID is missing
            global.document.dispatchEvent(
                clientWarningEvent({ message: "Quote ID is missing." })
            );
            global.history.replaceState(null, '', `${window.location.protocol}//${window.location.host}`);
            return;
        }
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="h-full flex items-center justify-center">
                <rm-progress-spinner></rm-progress-spinner>
            </div>
        `;
    }
}

global.customElements.define("rm-rs-load-quote", RSLoadQuote);
