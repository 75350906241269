import { LitElement, html } from "lit-element";
import { subscribe, unsubscribe } from "redux-subscribe";

import { noop } from "../../core/actions/actions";
import store from "../../store";

import "../appFooter";
import "../quoteDetailsPanel";
import "../catchmentPlanDetailsPanel";
import "./quoteSummaryCard";
import "./quoteTerritoriesList";
import { isCatchmentPlan } from "../../core/selectors/planSelector";

class PresenterQuotePanel extends LitElement {
    constructor() {
        super();

        // enable panel visibility once the quote is loaded
        store.dispatch(
            subscribe("territories", "PresenterQuotePanel", (data) => {
                if (data.next.length) {
                    this.shadowRoot.host.classList.remove("hidden");
                    return unsubscribe("territories", "PresenterQuotePanel");
                }
                return noop("PresenterQuotePanel");
            })
        );
    }

    static get properties() {
        return {
            isTerritoryVolumeViewable: { type: Boolean },
        };
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div
                class="panel-summary-list md:w-2/5 lg:w-1/3 xl:w-dash-1 print:hidden"
            >
                ${isCatchmentPlan() ? 
                    html`<rm-catchment-plan-details-panel></rm-catchment-plan-details-panel>` : 
                    html`<rm-quote-details-panel></rm-quote-details-panel>`
                }    

                <rm-client-quote-summary-card></rm-client-quote-summary-card>
                <rm-client-quote-territories-list
                    .isTerritoryVolumeViewable=${this.isTerritoryVolumeViewable}
                ></rm-client-quote-territories-list>
                <rm-app-footer></rm-app-footer>
            </div>
        `;
    }
}

global.customElements.define("rm-presenter-quote-panel", PresenterQuotePanel);
