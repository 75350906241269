import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import { FIREBASE_CONFIG } from "../../config/local";

let firebaseApp;

try {
    // Initialize Firebase
    firebaseApp = initializeApp(FIREBASE_CONFIG);
} catch (error) {
    console.error("Failed to init the Firebase app.");
    console.error(error);
}

export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
