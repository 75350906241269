import {
    CATCHMENT_PLAN_UPDATE,
    CATCHMENT_PLAN_RESET,
    PROXIMITY_PLAN_UPDATE,
    PROXIMITY_PLAN_RESET,
} from "../actions/actionTypes";

export const resetCatchmentPlan = () => {
    return {
        type: CATCHMENT_PLAN_RESET,
    };
}

export const updateCatchmentPlan = (payload) => {
    return {
        type: CATCHMENT_PLAN_UPDATE,
        payload,
    };
}

export const resetProximityPlan = () => {
    return {
        type: PROXIMITY_PLAN_RESET,
    };
}

export const updateProximityPlan = (payload) => {
    return {
        type: PROXIMITY_PLAN_UPDATE,
        payload,
    };
}
