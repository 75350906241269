import { html } from "lit-element";

import {
    StatefulButtonBase,
    PROGRESS,
    ACTIVE,
} from "../base/statefulButtonBase";
import "../progressSpinner";

import { save } from "../../services/httpApis/saveQuote";
import { nothing } from "lit-html";
import { saveCatchmentPlan } from "../../services/plansManagement";

export const clientQuoteSaveEvent = new Event("client:quote:save");

class SaveQuoteButton extends StatefulButtonBase {
    constructor() {
        super();
    }

    static get properties() {
        return {
            saveAsNew: { type: Boolean },
        };
    }

    async save() {
        this.status = PROGRESS;
        await saveCatchmentPlan();
        await save(this.saveAsNew);
        this.dispatchEvent(clientQuoteSaveEvent);
        this.status = ACTIVE;
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="flex flex-row items-center">
                <rm-progress-spinner
                    class="mr-2 ${PROGRESS === this.status
                        ? nothing
                        : "hidden"}"
                ></rm-progress-spinner>
                <button
                    @click=${() => this.save()}
                    title="Save Quote"
                    class="${this.itemCss()}"
                >
                    <span class="text-sm font-medium uppercase"
                        >Save Quote</span
                    >
                </button>
            </div>
        `;
    }
}

global.customElements.define("rm-save-quote-button", SaveQuoteButton);
