import { LitElement, html } from "lit-element";

export class ProgressSpinner extends LitElement {
    render() {
        return html`
            <style>
                .cssload-container {
                    width: 100%;
                    height: 28px;
                    text-align: center;
                    overflow: hidden;
                }

                .cssload-speeding-wheel {
                    width: 24px;
                    height: 24px;
                    margin: 0 auto;
                    border: 2px solid var(--blue);
                    border-radius: 50%;
                    border-left-color: transparent;
                    border-right-color: transparent;
                    animation: cssload-spin 575ms infinite linear;
                    -o-animation: cssload-spin 575ms infinite linear;
                    -ms-animation: cssload-spin 575ms infinite linear;
                    -webkit-animation: cssload-spin 575ms infinite linear;
                    -moz-animation: cssload-spin 575ms infinite linear;
                }

                @keyframes cssload-spin {
                    100% {
                        transform: rotate(360deg);
                        transform: rotate(360deg);
                    }
                }

                @-o-keyframes cssload-spin {
                    100% {
                        -o-transform: rotate(360deg);
                        transform: rotate(360deg);
                    }
                }

                @-ms-keyframes cssload-spin {
                    100% {
                        -ms-transform: rotate(360deg);
                        transform: rotate(360deg);
                    }
                }

                @-webkit-keyframes cssload-spin {
                    100% {
                        -webkit-transform: rotate(360deg);
                        transform: rotate(360deg);
                    }
                }

                @-moz-keyframes cssload-spin {
                    100% {
                        -moz-transform: rotate(360deg);
                        transform: rotate(360deg);
                    }
                }
            </style>
            <div class="cssload-container">
                <div class="cssload-speeding-wheel"></div>
            </div>
        `;
    }
}

global.customElements.define("rm-progress-spinner", ProgressSpinner);
