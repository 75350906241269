import { subscribe } from "redux-subscribe";
import { map } from "../services/googleMap";

import store from "../store";
import { territoryUpdateCustomerChanges, territoryRoundVisibilityToggle } from "../core/actions/territoryActions";

import {
    GMAP_ROUNDS_LAYERS,
    reduxActiveRoundsUpdateEvent,
    reduxInactiveRoundsUpdateEvent,
    drawingEnableFeaturesInteractivity,
    drawingDisableFeaturesInteractivity,
} from "../const/dataLayers";
const { URBAN, POSTIES, RURAL, POBOXES, COUNTERS, INACTIVE } = GMAP_ROUNDS_LAYERS;

import {
    getActiveTerritory,
    getInactiveTerritories,
    getAllTerritories,
    getTerritoryById,
    territoryActive,
} from "../core/selectors/territorySelector";
import { noop } from "../core/actions/actions";

import { roundDetailsHTML } from "./mapControls/infoWindow";
import { isMapMode } from "../services/controlsModes";
import { isEmptyWalk } from "../core/selectors/roundSelector";
import { EMPTY_WALK_BUTTON } from "../const/drawingMode";
import { URL_MODE_PARAMS } from "../const/urlParams";
import { getUrlTerritoryId, isUrlContainMode } from "../services/urlHeplers";
import { roundsByNeighbour } from "../services/httpApis/roundsQueries";

const mapDataLayers = {};
let boxSymbolSelected, boxSymbolUnselected, boxSymbolCustomerSelected, boxSymbolCustomerUnselected, boxSymbolInactive;
let inactiveLayerDefaults;

// TODO: optimize how styling is called
export const initDataLayers = () => {
    const visibility = store.getState().networkVisibility;

    boxSymbolSelected = {
        fillColor: "#CC0000",
        strokeColor: "#CC0000",
        strokeWeight: 3,
        fillOpacity: 0.5,
        scale: 12,
        path: global.google.maps.SymbolPath.CIRCLE,
    };

    boxSymbolUnselected = {
        ...boxSymbolSelected,
        fillOpacity: 0,
    };

    boxSymbolCustomerSelected = {
        ...boxSymbolSelected,
        fillColor: "green",
        fillOpacity: 0.75,
    };

    boxSymbolCustomerUnselected = {
        ...boxSymbolSelected,
        fillColor: "black",
        fillOpacity: 0.75,
    };

    boxSymbolInactive = {
        ...boxSymbolSelected,
        fillColor: "#2F446A",
        strokeColor: "#2F446A",
    };

    inactiveLayerDefaults = {
        map,
        style: {
            fillOpacity: 0.75,
            strokeWeight: 1,
            zIndex: 1,
            clickable: false,
        },
    };

    mapDataLayers[INACTIVE] = {};

    mapDataLayers[URBAN] = new global.google.maps.Data({
        map: map,
        style: {
            fillColor: "#003492",
            fillOpacity: 0.3,
            strokeColor: "#003492",
            strokeWeight: 1,
            zIndex: 2,
            visible: visibility[URBAN],
        },
    });

    mapDataLayers[RURAL] = new global.google.maps.Data({
        map: map,
        style: {
            fillColor: "#e932d6",
            fillOpacity: 0.3,
            strokeColor: "#e932d6",
            strokeWeight: 1,
            zIndex: 3,
            visible: visibility[RURAL],
        },
    });

    mapDataLayers[POSTIES] = new global.google.maps.Data({
        map: map,
        style: {
            fillColor: "#CC0000",
            fillOpacity: 0.3,
            strokeColor: "#CC0000",
            strokeOpacity: 0.3,
            strokeWeight: 1,
            zIndex: 4,
            visible: visibility[POSTIES],
        },
    });

    mapDataLayers[POBOXES] = new global.google.maps.Data({
        map: map,
        style: {
            icon: boxSymbolSelected,
            zIndex: 5,
            visible: visibility[POBOXES],
        },
    });

    mapDataLayers[COUNTERS] = new global.google.maps.Data({
        map: map,
        style: {
            icon: boxSymbolSelected,
            zIndex: 5,
            visible: false,
        },
    });
};

const emptyWalkStyle = {
    fillColor: "#333333",
    strokeColor: "#333333",
};

const noBoundaryStyle = {
    strokeWeight: 0,
};

export const assignLayersEventHandlers = (options = { rightClick: {} }) => {
    Object.keys(mapDataLayers)
        .filter((layer) => layer != INACTIVE)
        .forEach((layer) => {
            if (options.rightClick[layer]) {
                addRightClickEvent(mapDataLayers, layer);
            }

            mapDataLayers[layer].addListener("click", (event) => {
                // User can toggle visibility or access round info based on current drawing mode selection

                // Display round details
                if (isMapMode("INFO")) {
                    openInfoWindow(event);
                }

                // Toggle round selection
                if (isMapMode("NONE")) {
                    const roundId = event.feature.getId();

                    // expand neighbour rounds
                    roundsByNeighbour([roundId]);

                    // handle polygon selection based on app view
                    if (IS_CLIENT_APP) {
                        if (store.getState().settings.isQuoteEditable) {
                            store.dispatch(territoryUpdateCustomerChanges(roundId));
                        }
                    } else {
                        store.dispatch(territoryRoundVisibilityToggle(roundId));
                    }

                    document.dispatchEvent(reduxActiveRoundsUpdateEvent);
                }
            });
        });
};

const addRightClickEvent = (mapDataLayers, layer) => {
    mapDataLayers[layer].addListener("rightclick", (event) => {
        openInfoWindow(event);
    });
};

const openInfoWindow = (event) => {
    const infoWindow = new global.google.maps.InfoWindow({
        position: event.latLng,
        content: roundDetailsHTML(event.feature),
    });

    infoWindow.open(map);
};

const toggleLayersVisibility = (visibility) => {
    Object.keys(visibility)
        .filter((layer) => layer != INACTIVE)
        .forEach((layer) => {
            mapDataLayers[layer].setStyle({
                ...mapDataLayers[layer].getStyle(),
                ...{ visible: visibility[layer] },
            });
        });
};

const flushInteractiveLayers = () => {
    Object.keys(mapDataLayers)
        .filter((layer) => layer != INACTIVE)
        .forEach((layer) => {
            mapDataLayers[layer].forEach((feature) => mapDataLayers[layer].remove(feature));
        });
};

const renderInactive = (renderBoundary = true, allTerritories = false) => {
    const inactiveTerritories = {};
    const territories = allTerritories ? getAllTerritories() : getInactiveTerritories();

    territories
        .filter((territory) => shouldRenderTerritory(territory.id))
        .forEach((territory) => (inactiveTerritories[territory.id] = territory));

    // unrender obsolete inactive territories
    Object.keys(mapDataLayers[INACTIVE])
        .filter((territoryId) => !Object.keys(inactiveTerritories).includes(territoryId))
        .map((territoryId) => {
            mapDataLayers[INACTIVE][territoryId].setMap(null);
            delete mapDataLayers[INACTIVE][territoryId];
        });

    // create new inactive territories
    Object.keys(inactiveTerritories)
        .filter((territoryId) => !Object.keys(mapDataLayers[INACTIVE]).includes(territoryId))
        .map((territoryId) => {
            const territory = inactiveTerritories[territoryId];
            renderTerritoryAsInActive(territory, renderBoundary);
        });
};

const renderTerritoryAsInActive = (territory, renderBoundary) => {
    const featureOpts = { idPropertyName: "walk_id" };
    const allFeatures = store.getState().roundsData;
    // override styling with the territory specific settings
    const layerConfig = {
        ...inactiveLayerDefaults,
        style: {
            ...inactiveLayerDefaults.style,
            fillColor: territory.colour,
            strokeColor: territory.colour,
            ...(renderBoundary ? {} : noBoundaryStyle),
            visible: true,
        },
    };
    mapDataLayers[INACTIVE][territory.id] = new global.google.maps.Data(layerConfig);

    // get its selected rounds in selected networks
    Object.keys(territory.rounds)
        .filter(
            (roundId) =>
                territory.rounds[roundId].selected &&
                territory.networkSelections.includes(territory.rounds[roundId].layer)
        )
        .map((roundId) => {
            // check if the round has geometry associated (there's none for appartments)
            if (null !== allFeatures[roundId].geometry) {
                const feature = mapDataLayers[INACTIVE][territory.id].addGeoJson(allFeatures[roundId], featureOpts)[0];
                if ("Point" == feature.getGeometry().getType()) {
                    // POBOX case - render it with a symbol
                    mapDataLayers[INACTIVE][territory.id].overrideStyle(feature, { icon: boxSymbolInactive });
                }
            }
        });
};

const renderActiveRounds = (flush = false) => {
    if (flush) {
        flushInteractiveLayers();
    }

    // in case active not included in quotables
    const activeTerritory = getActiveTerritory() || {};
    const territoryRounds = activeTerritory.rounds || {};

    if (Object.entries(territoryRounds).length === 0) {
        flushInteractiveLayers();
        return;
    }

    // TODO: optimize to re-render only changed items

    // google map data layer features will use walk_id as the feature id for each
    const featureOpts = { idPropertyName: "walk_id" };
    const allRounds = store.getState().roundsData;

    Object.keys(territoryRounds).forEach((walk_id) => {
        const round = territoryRounds[walk_id];
        if (!round || !round.layer || undefined === round.selected) {
            // TODO: report this issues so we can track down the source
            return;
        }

        const roundDef = allRounds[walk_id];
        let feature;

        // avoid doubled-features, another approach could be flushing entire layer every time we get here,
        // which has to be done to each feature one by one
        // make sure geometry exists too
        feature = mapDataLayers[round.layer].getFeatureById(walk_id);
        if (!feature && roundDef && null !== roundDef.geometry) {
            feature = mapDataLayers[round.layer].addGeoJson(roundDef, featureOpts)[0];
        }

        // make sure feature actually exists, although all the measures are taken
        // TODO: report this issue so we can track down the source
        if (!feature) return;

        const emptyButton = store.getState().mapDrawingMode[EMPTY_WALK_BUTTON];
        const renderEmpty = isEmptyWalk(Number(walk_id)) && !emptyButton;
        const renderAdded =
            activeTerritory.customerChanges && activeTerritory.customerChanges.added.includes(Number(walk_id));
        const renderRemoved =
            activeTerritory.customerChanges && activeTerritory.customerChanges.removed.includes(Number(walk_id));
        const isPoBox = "Point" == feature.getGeometry().getType();

        const customerChangesStyle = {
            fillOpacity: 0.75,
        };

        // green polygon for proposed round addition
        if (renderAdded) {
            customerChangesStyle.fillColor = "green";
            if (isPoBox) {
                customerChangesStyle.icon = boxSymbolCustomerSelected;
            }

            mapDataLayers[round.layer].overrideStyle(feature, customerChangesStyle);
            return;
        }
        // red polygon for proposed round removal
        if (renderRemoved) {
            customerChangesStyle.fillColor = "red";
            if (isPoBox) {
                customerChangesStyle.icon = boxSymbolCustomerUnselected;
            }

            mapDataLayers[round.layer].overrideStyle(feature, customerChangesStyle);
            return;
        }
        // usual colours for unchanged polygons
        if (round.selected) {
            if (renderEmpty) {
                mapDataLayers[round.layer].overrideStyle(feature, {
                    fillOpacity: 0.3,
                    ...emptyWalkStyle,
                });
                return;
            }
            mapDataLayers[round.layer].revertStyle(feature);
        } else {
            if (store.getState().settings.active && store.getState().settings.hideSurrounding) {
                mapDataLayers[round.layer].remove(feature);
                return;
            }
            if (isPoBox) {
                const style = {
                    icon: boxSymbolUnselected,
                    ...(renderEmpty && emptyWalkStyle),
                };
                mapDataLayers[round.layer].overrideStyle(feature, style);
            } else {
                const style = {
                    fillOpacity: 0,
                    ...(renderEmpty && emptyWalkStyle),
                };
                if (!renderEmpty) {
                    mapDataLayers[round.layer].revertStyle(feature);
                }
                mapDataLayers[round.layer].overrideStyle(feature, style);
            }
        }
    });
};

store.dispatch(
    subscribe("networkVisibility", "toggleLayersVisibility", (data) => {
        toggleLayersVisibility(data.next);
        return noop();
    })
);

// this may be improved to use pure redux listener, however, it will involve
// checking all territories rounds object for changes
document.addEventListener(reduxActiveRoundsUpdateEvent.type, () => {
    renderActiveRounds();
});

// this is triggered when a territory gets deleted or entire job is flushed
// or when the quote is loaded and things get cleaned up
document.addEventListener(reduxInactiveRoundsUpdateEvent.type, () => {
    if (isUrlContainMode(URL_MODE_PARAMS.MAP_ONLY)) {
        renderInactive(false, true);
    } else {
        renderInactive();
    }
});

document.addEventListener(drawingEnableFeaturesInteractivity.type, () => {
    Object.keys(mapDataLayers)
        .filter((layer) => layer != INACTIVE)
        .map((layer) => {
            mapDataLayers[layer].setStyle({
                ...mapDataLayers[layer].getStyle(),
                clickable: true,
            });
        });
});

document.addEventListener(drawingDisableFeaturesInteractivity.type, () => {
    Object.keys(mapDataLayers)
        .filter((layer) => layer != INACTIVE)
        .map((layer) => {
            mapDataLayers[layer].setStyle({
                ...mapDataLayers[layer].getStyle(),
                clickable: false,
            });
        });
});

store.dispatch(
    subscribe("activeTerritory", "renderRoundsToLayers", (data) => {
        // protect on INIT action when prev value is NULL
        if (data.prev) {
            if (isUrlContainMode(URL_MODE_PARAMS.MAP_ONLY)) {
                renderInactive(false, true);
            } else {
                renderInactive();
                renderActiveRounds(true);
            }
            if (!shouldRenderTerritory(data.next)) {
                flushTerritory(data.next);
            }
        }
        return noop();
    })
);

store.dispatch(
    subscribe("emptyWalks", "renderEmptyWalks", (data) => {
        // protect on INIT action when prev value is an empty array
        // NOTE: If there are no empties in the payload of the action, then it won't do
        // anything depending whether it was a prev or next state
        if (data.prev.length > 0) {
            renderInactive();
            renderActiveRounds(true);
        }
        return noop("renderEmptyWalks");
    })
);

const shouldRenderTerritory = (territoryId) => {
    // there are following scenarios when we should check to NOT render the territory
    // 1. single territory mode and the territory is not the one in URL
    if (isUrlContainMode(URL_MODE_PARAMS.SINGLE_TERRITORY)) {
        return getUrlTerritoryId() === territoryId;
    }

    // 2. quote document settings tell us to render only active territory
    if (!territoryActive(territoryId)) {
        const settings = store.getState().settings;
        if (!IS_CLIENT_APP && settings?.builderShowActiveTerritoryOnly) {
            return false;
        }

        if (IS_CLIENT_APP && settings?.viewerShowActiveTerritoryOnly) {
            return false;
        }
    }

    return true;
};

const flushTerritory = (territoryId) => {
    const layer = mapDataLayers[INACTIVE][territoryId];

    if (layer) {
        layer.setMap(null);
    }
    const territory = getTerritoryById(territoryId);

    // territory might be unavailable for the mode quote loaded in, ie a single territory mode
    // make sure territory is an object or bail out
    if (!territory) return;

    Object.keys(territory.rounds).forEach((walk_id) => {
        const round = territory.rounds[walk_id];
        const feature = mapDataLayers[round.layer].getFeatureById(walk_id);
        if (feature) mapDataLayers[round.layer].remove(feature);
    });
};
