import {
    GoogleAuthProvider,
    onIdTokenChanged,
    signInAnonymously,
    signInWithRedirect,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { CUSTOM_OAUTH_PARAMETERS } from "../../config/local";
import { auth, firestore } from "./init";

export const clientUserAuthenticatedEvent = new Event(
    "client:user:authenticated"
);

export const isFeatureEnabled = async (feature) => {
    if (!auth.currentUser) return false;

    const docSnap = await getDoc(doc(firestore, "features", feature));

    if (!docSnap.exists()) {
        return true; // Enabled by default
    }

    const data = docSnap.data();

    if (!data.limitUsage) return true;

    return data.allowList.includes(auth.currentUser.email);
};

onIdTokenChanged(auth, async (user) => {
    if (null === user) {
        if (IS_CLIENT_APP) {
            signInAnonymously(auth);
        } else {
            console.log("Sign in with redirect");
            const provider = new GoogleAuthProvider();
            provider.setCustomParameters(CUSTOM_OAUTH_PARAMETERS);
            await signInWithRedirect(auth, provider);
        }
    } else {
        document.dispatchEvent(clientUserAuthenticatedEvent);
    }
});

export const waitForLogin = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                unsubscribe();
                resolve(true);
            }
        }, reject);
    });
};
