import { LitElement, css, html } from "lit-element";
import { nothing } from "lit-html";

import { unsafeHTML } from "lit-html/directives/unsafe-html";
import debounce from "../services/debounce";
import bubbleIcon from "./svg/bubble";
import questionIcon from "./svg/question";
import warningIcon from "./svg/warning";

export const clientWarningEvent = (detail) =>
    new CustomEvent("client:warning:message", {
        detail,
        bubbles: true,
        composed: true,
    });


export const clientWarningNoDelayEvent = (detail) =>
    new CustomEvent("client:warning:message:no:delay", {
        detail,
        bubbles: true,
        composed: true,
});

export const clientUnsafeWarningEvent = (detail) => {
    const details = { ...detail, allowUnsafeHtml: true, truncated: false };

    return new CustomEvent("client:unsafe:warning:message", {
        detail: details,
        bubbles: true,
        composed: true,
    });
};
export const clientAckEvent = (detail) =>
    new CustomEvent("client:ack:message", {
        detail,
        bubbles: true,
        composed: true,
    });
export const clientInfoEvent = (detail) =>
    new CustomEvent("client:info:message", {
        detail,
        bubbles: true,
        composed: true,
    });
export const clientInfoNoDelayEvent = (detail) =>
    new CustomEvent("client:info:message:no:delay", {
        detail,
        bubbles: true,
        composed: true,
    });

class Toast extends LitElement {
    constructor() {
        super();
        this.activeClass = "";
        this.message = "";
        this.icon = "";
        this.interactive = false;
        this.allowUnsafeHtml = false;
        this.truncated = true;
    }

    static get properties() {
        return {
            activeClass: { type: String },
            message: { type: String },
            icon: { type: String },
            interactive: { type: Boolean },
            allowUnsafeHtml: { type: Boolean },
            truncated: { type: Boolean },
        };
    }

    static get styles() {
        return css`
            #wrapper {
                max-width: 40px;
                bottom: 30px;
            }

            #icon {
                width: 38px;
                stroke-width: 32px;
            }

            #wrapper.show-then-hide {
                visibility: visible;
                animation: toast-fadein 0.5s, toast-expand 0.5s 0.5s,
                    toast-stay 7s 1s, toast-shrink 0.5s 8s,
                    toast-fadeout 0.5s 8.5s;
            }

            #wrapper.show-stay {
                visibility: visible;
                animation: toast-fadein 0.5s, toast-expand 0.5s 0.5s forwards;
            }

            #wrapper.show-hide {
                visibility: visible;
                animation: toast-shrink 0.5s, toast-fadeout 0.5s 1s;
            }

            @keyframes toast-fadein {
                from {
                    bottom: 0;
                    opacity: 0;
                }
                to {
                    bottom: 30px;
                    opacity: 1;
                }
            }

            @keyframes toast-expand {
                from {
                    min-width: 40px;
                }
                to {
                    min-width: 420px;
                }
            }

            @keyframes toast-stay {
                from {
                    min-width: 420px;
                }
                to {
                    min-width: 420px;
                }
            }

            @keyframes toast-shrink {
                from {
                    min-width: 420px;
                }
                to {
                    min-width: 40px;
                }
            }

            @keyframes toast-fadeout {
                from {
                    bottom: 30px;
                    opacity: 1;
                }
                to {
                    bottom: 60px;
                    opacity: 0;
                }
            }
        `;
    }

    firstUpdated() {
        // misc nice animation hooks
        const wrapperEl = this.shadowRoot.querySelector("#wrapper");
        wrapperEl.addEventListener("animationend", (e) =>
            "toast-fadeout" === e.animationName ? this.flush() : null
        );

        // simple info toast
        document.addEventListener(
            clientInfoEvent(null).type,
            debounce((e) => {
                this.message = e.detail.message;
                this.icon = bubbleIcon;
                this.activeClass = "show-then-hide";
            }, 1000)
        );

        // simple info toast without delay
        document.addEventListener(clientInfoNoDelayEvent(null).type, (e) => {
            this.message = e.detail.message;
            this.icon = bubbleIcon;
            this.activeClass = "show-then-hide";
        });

        // simple warning toast - differs by icon really, can be generalised with above one day
        // TODO: see if this can be simplified
        document.addEventListener(
            clientWarningEvent(null).type,
            debounce((e) => {
                this.message = e.detail.message;
                this.icon = warningIcon;
                this.activeClass = "show-then-hide";
            }, 1000)
        );

        document.addEventListener(
            clientWarningNoDelayEvent(null).type,
                (e) => {
                    this.message = e.detail.message;
                    this.icon = warningIcon;
                    this.activeClass = "show-then-hide";
                }
        );

        document.addEventListener(
            clientUnsafeWarningEvent(null).type,
            debounce((e) => {
                this.message = e.detail.message;
                this.truncated = e.detail.truncated || true;
                this.allowUnsafeHtml = e.detail.allowUnsafeHtml || false;
                this.icon = warningIcon;
                this.activeClass = "show-then-hide";
            }, 1000)
        );

        // toast requires acknowledgement
        document.addEventListener(
            clientAckEvent(null).type,
            debounce((e) => {
                this.message = e.detail.message;
                this.icon = questionIcon;
                this.interactive = true;
                this.activeClass = "show-stay";
            }, 1000)
        );
    }

    flush() {
        this.activeClass = "";
        this.message = "";
        this.icon = nothing;
    }

    ok() {
        this.activeClass = "show-hide";
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div
                id="wrapper"
                class="invisible overflow-hidden inset-x-0 m-auto bg-sunrise rounded fixed z-10 flex items-center ${this
                    .activeClass}"
            >
                <div
                    id="icon"
                    class="flex-none text-center text-black leading-none"
                >
                    ${this.icon}
                </div>
                <div
                    id="message"
                    class="self-stretch flex-grow py-3 px-2 text-sm bg-blue text-white ${this
                        .truncated
                        ? "truncate"
                        : ""}"
                >
                    ${this.allowUnsafeHtml
                        ? html`${unsafeHTML(this.message)}`
                        : html`${this.message}`}
                </div>
                <div
                    id="ok"
                    class="${this.interactive
                        ? ""
                        : "xhidden"} self-stretch flex-none flex bg-blue px-2"
                >
                    <button
                        @click=${(_) => this.ok()}
                        class="px-2 rounded-sm self-center bg-peppermint text-white"
                    >
                        <span class="text-sm font-medium uppercase">OK</span>
                    </button>
                </div>
            </div>
        `;
    }
}

global.customElements.define("rm-toast", Toast);
