import { LitElement, html } from "lit-element";
import { classMap } from "lit-html/directives/class-map";

import "../progressSpinner";
import "./importRoundsButton";
import { PROGRESS, ACTIVE } from "../base/statefulButtonBase";

import { map } from "../../services/googleMap";
import { clientDialogCloseEvent } from "../dialog";
import {
    getRoundsByList,
    getRoundsBoundingBox,
    getRoundsLayers,
    getRoundsByPostCodes,
} from "../../services/httpApis/roundsQueries";
import store from "../../store";
import { roundsNetworkToggle } from "../../core/actions/actions";
import { isTerritoryTargeted } from "../../core/selectors/targetingSelector";
import { getTerritoryById } from "../../core/selectors/territorySelector";
import { targetActiveTerritory } from "../../services/httpApis/targetingQuery";
import { parseCsv, territoriesFromCsvArray } from "../../services/imports";
import guid from "../../services/guid";
import { territoryActivate, territoryPush, territoryUpdateLocation } from "../../core/actions/territoryActions";
import { clientInfoEvent, clientWarningEvent } from "../toast";

export class ImportRounds extends LitElement {
    constructor() {
        super();
        this.btnStatus = ACTIVE;
        this.type = "walk";

        this.baseCss = {
            "w-48": true,
            "bg-sunrise": false,
            border: true,
            "border-sunrise": true,
            "hover:bg-sunrise": true,
            "font-medium": true,
            "py-2": true,
            "px-4": true,
            "focus:outline-none": true,
            "mr-1": true,
        };
        this.activeCss = {
            ...this.baseCss,
            "bg-sunrise": true,
            "border-sunrise": true,
            "hover:bg-sunrise": false,
        };

        this.disabledCss = {
            ...this.baseCss,
            "hover:bg-peppermint-50": false,
            "cursor-not-allowed": true,
        };
    }

    static get properties() {
        return {
            type: { type: String },
            btnStatus: { type: String },
        };
    }

    firstUpdated() {
        super.firstUpdated();
        this.importTextField.focus();
    }

    get importTextField() {
        return this.shadowRoot.querySelector("textarea");
    }

    get importPostcodesAsTerritories() {
        return this.shadowRoot.querySelector("#create-territories");
    }

    async importRounds() {
        let rounds, box;
        let input = this.importTextField.value;
        input = input.split(/\D+/gm).map((item) => item.trim());

        this.btnStatus = PROGRESS;
        if ("walk" == this.type) {
            // get all rounds geometries and bounding box
            try {
                [rounds, box] = await Promise.all([getRoundsByList(input), getRoundsBoundingBox(input)]);
            } catch (error) {
                console.log(error);
            } finally {
                this.btnStatus = ACTIVE;

                // update territory location
                store.dispatch(
                    territoryUpdateLocation({
                        boundingBox: box || null,
                    })
                );

                // get respective layers selected, presume imported rounds can not have conflicting networks
                // TODO: ensure this edge cases with conflicting layers are processed
                store.dispatch(roundsNetworkToggle(getRoundsLayers(input)));
            }
        } else if ("post_code" == this.type) {
            try {
                if (this.importPostcodesAsTerritories.checked) {
                    // create a new territory per each postcode
                    await this.territoriesByPostcodes(input);
                } else {
                    // simply import into a single current territory
                    // get all rounds geometries and bounding box
                    rounds = await getRoundsByPostCodes(input);
                    box = await getRoundsBoundingBox(rounds);

                    // update territory location
                    store.dispatch(
                        territoryUpdateLocation({
                            boundingBox: box || null,
                        })
                    );
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.btnStatus = ACTIVE;
            }
        } else if ("basic" == this.type) {
            try {
                // remember current active territory and restore it later
                const activeTerritory = store.getState().activeTerritory;
                await this.basicImport(this.importTextField.value);
                store.dispatch(territoryActivate(activeTerritory));
            } catch (error) {
                console.log(error);
                document.dispatchEvent(
                    clientWarningEvent({
                        message: "No valid import format is found, please try again.",
                    })
                );
            } finally {
                this.btnStatus = ACTIVE;
            }
        }

        // close the dialog
        document.dispatchEvent(clientDialogCloseEvent);
        // adjust the map
        if (box) {
            map.fitBounds(box);
        }

        isTerritoryTargeted(store.getState().activeTerritory) ? targetActiveTerritory() : null;
    }

    async territoriesByPostcodes(postcodes) {
        for (const code of postcodes) {
            const id = `rm_${guid()}`;
            store.dispatch(territoryPush(id));

            const rounds = await getRoundsByPostCodes([code]);
            const box = await getRoundsBoundingBox(rounds);

            const territory = getTerritoryById(id);

            // update territory details
            const location = {
                ...territory.location,
                formatted: { main_text: code },
                boundingBox: box || null,
            };

            store.dispatch(territoryUpdateLocation(location, id));
            store.dispatch(roundsNetworkToggle(getRoundsLayers(Object.keys(territory.rounds))));
        }
    }

    async basicImport(input) {
        const csvArray = parseCsv(input);
        const territories = await territoriesFromCsvArray(csvArray);

        // update state with imported territories
        for (let territory of territories) {
            const id = `rm_${guid()}`;
            store.dispatch(territoryPush(id));
            territory.rounds = await getRoundsByList(territory.walkIds);

            const box = await getRoundsBoundingBox(territory.walkIds);
            const location = {
                ...territory.location,
                formatted: { main_text: territory.name },
                boundingBox: box || null,
            };

            store.dispatch(territoryUpdateLocation(location, id));
            store.dispatch(roundsNetworkToggle(getRoundsLayers(territory.walkIds)));
        }

        const numRounds = territories.reduce((acc, current) => {
            return (acc += current.rounds.length);
        }, 0);
        document.dispatchEvent(
            clientInfoEvent({
                message: `${territories.length} territories have been created, ${numRounds} ${
                    numRounds > 1 ? "rounds" : "round"
                } have been imported.`,
            })
        );
    }

    toggleType(type) {
        this.type = type;
        this.importTextField.focus();
    }

    toggleCss(active) {
        return active ? this.activeCss : this.baseCss;
    }

    render() {
        return html`
            <style>
                @import "main.css";
            </style>
            <div class="flex flex-col h-full">
                <div class="flex flex-wrap flex-grow-0 justify-between py-2 px-4 -mx-4 border-b border-dark-grey">
                    <h3 class="text-xl font-medium">Import Rounds</h3>
                    <button
                        @click=${() => document.dispatchEvent(clientDialogCloseEvent)}
                        title="Close [Esc]"
                        class="px-4 py-1 rounded border border-peppermint hover:bg-peppermint-50"
                    >
                        <span class="text uppercase">
                            Close
                            <span class="text-xs lowercase">[Esc]</span>
                        </span>
                    </button>
                </div>
                <div class="flex flex-grow-0 pt-2">
                    <button
                        @click=${() => this.toggleType("walk")}
                        class="${classMap(this.toggleCss("walk" == this.type))}"
                    >
                        By Walk ID(s)
                    </button>
                    <button
                        @click=${() => this.toggleType("post_code")}
                        class="${classMap(this.toggleCss("post_code" == this.type))}"
                    >
                        By Post Code(s)
                    </button>
                    <button
                        @click=${() => this.toggleType("basic")}
                        class="${classMap(this.toggleCss("basic" == this.type))}"
                    >
                        Bulk Basic
                    </button>
                    <button @click=${() => this.toggleType("advanced")} disabled class="${classMap(this.disabledCss)}">
                        Bulk Advanced
                    </button>
                </div>
                <div class="flex-1 pt-2 pb-4">
                    <textarea id="territory-rounds-import" class="w-full h-32 border border-dark-grey pt-1"></textarea>
                    <p class="${"basic" === this.type ? "" : "hidden"} font-medium">
                        Paste the import CSV in the text box above. Note the individual line format is "Territory Name",
                        "Key Address", "Walk ID", "Key Geo Coordinates". One line per Walk ID. Please ensure each column
                        value is wrapped within double quotation marks (e.g "Auckland", "Greenland", "7020"). New
                        territory will be created for each unique Territory name. "Key Address" is optional and
                        coordinates will be used to place the territory marker then.
                    </p>
                    <div class="flex-grow ${"post_code" === this.type ? "" : "hidden"}">
                        <label class="text-sm select-none" for="create-territories">Individual territories</label>
                        <input type="checkbox" class="checkbox align-middle" id="create-territories" />
                        <span class="bg-peppermint-50 text-sm px-2 py-1 ml-2"
                            >Create new territory for each post code</span
                        >
                    </div>
                </div>

                <div
                    class="flex flex-row flex-row-reverse flex-grow-0 -mx-4 pt-4 px-4 border-t border-dark-grey items-center"
                >
                    <rm-import-rounds-btn
                        .status=${this.btnStatus}
                        @click=${() => this.importRounds()}
                    ></rm-import-rounds-btn>
                </div>
            </div>
        `;
    }
}

global.customElements.define("rm-import-rounds", ImportRounds);
