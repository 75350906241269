import { getIdToken } from "firebase/auth";
import { auth } from "../../services/firebase/init";
import { OCI_GATEWAY_URL, OCI_GATEWAY_URL_V1 } from "../../config/local";
import { plainWarning } from "../notifications/apiWrapper";
import { clientWarningEvent } from "../../components/toast";

export const getCustomer = async (cmsCode) => {
    if (!auth.currentUser) {
        return null;
    }

    const token = await getIdToken(auth.currentUser);
    try {
        const url = `${OCI_GATEWAY_URL}/customer/by-account-code?cmsCode=${cmsCode}`;
        const response = await fetch(url, {
            headers: {
                "Firebase-Token": token,
            },
        });

        if (!response.ok && 404 == response.status) {
            global.document.dispatchEvent(
                clientWarningEvent({
                    message: `Could not load data for account number ${cmsCode}.`,
                })
            );
        }
        return response.ok ? response.json() : null;
    } catch (error) {
        plainWarning("Network issues!", error);
        return null;
    }
};

export const searchCustomer = async (searchTerm) => {
    if (!auth.currentUser || !searchTerm) {
        return null;
    }

    const token = await getIdToken(auth.currentUser);
    try {
        const response = await fetch(
            `${OCI_GATEWAY_URL}/customer/search/${searchTerm}`,
            {
                headers: {
                    "Firebase-Token": `Bearer ${token}`,
                },
            }
        );
        if (!response.ok && 404 == response.status) {
            global.document.dispatchEvent(
                clientWarningEvent({
                    message: "No customer found.",
                })
            );
        }
        return response.ok ? response.json() : null;
    } catch (error) {
        plainWarning("Network issues!", error);
        return null;
    }
};

export const getCustomerContact = async (cmsCode) => {
    if (!auth.currentUser || !cmsCode) {
        return null;
    }

    const token = await getIdToken(auth.currentUser);
    try {
        const response = await fetch(
            `${OCI_GATEWAY_URL}/customer/contacts/${cmsCode}`,
            {
                headers: {
                    "Firebase-Token": `Bearer ${token}`,
                },
            }
        );
        if (!response.ok && 404 == response.status) {
            global.document.dispatchEvent(
                clientWarningEvent({
                    message: "No customer contact found.",
                })
            );
        }
        return response.ok ? response.json() : null;
    } catch (error) {
        plainWarning("Network issues!", error);
        return null;
    }
};

export const getCustomerStores = async (cmsCode) => {
    if (!auth.currentUser || !cmsCode) {
        return null;
    }

    const token = await getIdToken(auth.currentUser);
    try {
        const response = await fetch(
            `${OCI_GATEWAY_URL_V1}/customer/${cmsCode}/stores`,
            {
                headers: {
                    "Firebase-Token": `Bearer ${token}`,
                },
            }
        );
        if (!response.ok && 404 == response.status) {
            global.document.dispatchEvent(
                clientWarningEvent({
                    message: "No customer stores found.",
                })
            );
        }
        return response.ok ? response.json() : null;
    } catch (error) {
        plainWarning("Network issues!", error);
        return null;
    }
}
